import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";
import RichText from "~components/molecules/rich_text/rich_text";
import RscHubCardCoverImage from "~components/molecules/rsc_hb_card/rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import ModalForm from "~components/organisms/modal/modal_form/modal_form";
import useMatchMedia from "~hooks/use_match_media/use_match_media";
import { MEDIA, RICH_TEXT } from "../../../../prop_types";
import {
  innerStyle,
  leftStyle,
  rightStyle,
  textStyle,
  wrapperStyle,
} from "./sr_cta.module.scss";

function SRCTASection({
  a4CoverImage,
  buttonText,
  downloadFormDescription,
  downloadFormId,
  downloadFormTitle,
  lang,
  text,
  title,
}) {
  const [isDownloadModalActive, setIsDownloadModalActive] = useState(false);

  const toggleModal = useCallback(() => {
    return setIsDownloadModalActive((currentState) => {
      return !currentState;
    });
  }, []);

  const isMobileWindow = useMatchMedia({ max: "M" });

  return (
    <>
      <div className={wrapperStyle}>
        <Contain>
          <div className={innerStyle}>
            <div className={leftStyle}>
              <div>
                <RscHubCardCoverImage
                  portrait
                  hasCard
                  roundTop
                  roundBottom
                  cover={a4CoverImage}
                />
              </div>
            </div>
            <div className={rightStyle}>
              <Heading
                isCentered={isMobileWindow}
                visualLevel={3}
                color="white"
              >
                {title}
              </Heading>
              <div className={textStyle}>
                <RichText isCentered={isMobileWindow} text={text} />
              </div>
              <Button isCentered={isMobileWindow} onClick={toggleModal}>
                {buttonText}
              </Button>
            </div>
          </div>
        </Contain>
      </div>
      {/* ————— MODAL ————— */}
      <ModalForm
        formDescription={downloadFormDescription}
        formId={downloadFormId}
        formTitle={downloadFormTitle}
        isModalActive={isDownloadModalActive}
        lang={lang}
        setModalActiveState={setIsDownloadModalActive}
      />
    </>
  );
}

SRCTASection.propTypes = {
  a4CoverImage: MEDIA,
  buttonText: PropTypes.string,
  downloadFormDescription: PropTypes.string,
  downloadFormId: PropTypes.string,
  downloadFormTitle: PropTypes.string,
  lang: PropTypes.string,
  text: RICH_TEXT,
  title: PropTypes.string,
};

SRCTASection.defaultProps = {
  a4CoverImage: null,
  buttonText: "Download the PDF",
  downloadFormDescription: "We just need a few details from you first",
  downloadFormId: null,
  downloadFormTitle: "Download the report",
  lang: "en-gb",
  text: null,
  title: null,
};

export default SRCTASection;
