import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "~components/molecules/background_image/background_image";
import { COLOR, MEDIA } from "../../../../prop_types";
import SRHeroInner from "./components/sr_hero_inner/sr_hero_inner";
import { heroWrapperStyle } from "./sr_hero.module.scss";

function SRHero({
  backgroundImage,
  buttonText,
  downloadFormDescription,
  downloadFormId,
  downloadFormTitle,
  lang,
  reportExtraInformation,
  reportSubtitle,
  reportTitle,
  reportTypeNew,
  textColor,
  fontSize,
}) {
  return (
    <div className={heroWrapperStyle}>
      <BackgroundImage image={backgroundImage} objectFit="cover" />
      <SRHeroInner
        {...{
          buttonText,
          downloadFormDescription,
          downloadFormId,
          downloadFormTitle,
          fontSize,
          lang,
          reportExtraInformation,
          reportSubtitle,
          reportTitle,
          reportType: reportTypeNew,
          textColor,
        }}
      />
    </div>
  );
}

SRHero.propTypes = {
  backgroundImage: MEDIA,
  buttonText: PropTypes.string,
  downloadFormDescription: PropTypes.string,
  downloadFormId: PropTypes.string,
  downloadFormTitle: PropTypes.string,
  lang: PropTypes.string,
  reportTypeNew: PropTypes.string,
  fontSize: PropTypes.oneOf(["normal", "small"]),
  reportTitle: PropTypes.string,
  reportSubtitle: PropTypes.string,
  reportExtraInformation: PropTypes.string,
  textColor: COLOR,
};

SRHero.defaultProps = {
  backgroundImage: null,
  fontSize: "normal",
  buttonText: "Download the PDF",
  downloadFormDescription: "We just need a few details from you first",
  downloadFormId: null,
  downloadFormTitle: "Download the report",
  lang: "en-gb",
  reportTypeNew: null,
  reportTitle: null,
  reportExtraInformation: null,
  reportSubtitle: null,
  textColor: null,
};

export default SRHero;
