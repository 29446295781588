import React, { useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import ChapterPrevNextButtons from "~components/molecules/chapter_prev_next_buttons/chapter_prev_next_buttons";
import SRChapterCardNav from "~components/molecules/specialised_report/sr_chapter_card_nav/sr_chapter_card_nav";
import SRCTASection from "~components/molecules/specialised_report/sr_cta/sr_cta";
import SRHero from "~components/molecules/specialised_report/sr_hero/sr_hero";
import SRSection from "~components/molecules/specialised_report/sr_section/sr_section";
import TOCNav from "~components/organisms/toc_nav/toc_nav";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import useIntersect from "~hooks/use_intersect/use_intersect";
import usePageData from "~hooks/use_page_data/use_page_data";
import {
  ASSET,
  BLOK,
  BLOK_ARRAY,
  MEDIA,
  STORYBLOK_ENTRY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import useSmoothScrollToNav from "./hooks/use_smooth_scroll_to_nav";

const TEMPLATE_COMPONENTS = {
  specialisedReportSection: SRSection,
};

export default function TemplateSpecialisedReport(props) {
  const { story, pageContext, entryAssets } = usePageData(props);
  const {
    subNavData,
    UIStrings,
    next,
    previous,
    pagePath,
    reportHero,
    downloadFormId,
    ctaSection,
    lang,
    currentChapterIndex,
  } = pageContext;

  const { prevChapterIndex, shouldScrollToNav } = props.location?.state || {};
  const { scrollRef } = useSmoothScrollToNav({
    shouldScrollToNav,
  });

  // Reset location state on mount by navigating to same page without state
  // Avoids "replaying" scroll behavior on page refresh
  // This is a bit of a hack, but seems to be the most reliable way to clear location.state
  useEffect(() => {
    let queryString = "";

    if (typeof window !== "undefined") {
      queryString = window.location.search;
    }

    return navigate(`.${queryString}`, {
      state: { disableScrollUpdate: true },
      replace: true,
    });
  }, []);

  const allReportChapters = props.data?.chapters?.nodes || {};

  const [activeItem, setActiveItem] = useState(null);

  const { children, headerColor } = story;

  const sections =
    children?.length > 0 &&
    children.filter((child) => {
      return child.component === "specialisedReportSection";
    });

  const [bodyIORef, bodyIOEntry] = useIntersect({
    rootMargin: "-320px 0px 0px 0px",
  });

  const shouldShowTOCNav = bodyIOEntry.isIntersecting;

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      {...props}
      headerColor={headerColor}
    >
      <SRHero
        UIStrings={UIStrings}
        lang={lang}
        downloadFormId={downloadFormId}
        downloadFormTitle={UIStrings?.downloadFormTitle}
        downloadFormDescription={UIStrings?.downloadFormDescription}
        buttonText={UIStrings?.downloadButtonText}
        {...reportHero}
      />
      <div ref={scrollRef}>
        <SRChapterCardNav
          {...{
            chapters: allReportChapters,
            allChaptersText: UIStrings?.chaptersText,
            currentChapterIndex,
            prevChapterIndex,
          }}
        />
      </div>
      <TOCNav
        activeItem={activeItem}
        allChaptersTitle={UIStrings?.chaptersText}
        chapters={allReportChapters}
        conversionType="content_apm_report"
        currentChapterIndex={currentChapterIndex}
        downloadButtonText={UIStrings?.downloadButtonText}
        downloadFormDescription={UIStrings?.downloadFormDescription}
        downloadFormId={downloadFormId}
        downloadFormTitle={UIStrings?.downloadFormTitle}
        isVisible={shouldShowTOCNav}
        pagePath={pagePath}
        sections={sections}
      />
      <div ref={bodyIORef}>
        {children?.length > 0 &&
          children.map((childComponent) => {
            return renderDynamicComponent({
              parentComponentName: "TemplateSpecialisedReport",
              componentName: childComponent.component,
              componentsMap: TEMPLATE_COMPONENTS,
              callbackWhenInView: setActiveItem,
              ...childComponent,
            });
          })}
      </div>
      <ChapterPrevNextButtons
        previousTitle={UIStrings?.previousButtonTitle}
        previousDescription={previous?.title}
        nextTitle={UIStrings?.nextButtonTitle}
        nextDescription={next?.title}
        next={next}
        previous={previous}
      />
      <SRCTASection
        lang={lang}
        downloadFormId={downloadFormId}
        downloadFormTitle={UIStrings?.downloadFormTitle}
        downloadFormDescription={UIStrings?.downloadFormDescription}
        buttonText={UIStrings?.downloadButtonText}
        {...ctaSection}
      />
    </TemplateGlobalEntry>
  );
}

TemplateSpecialisedReport.propTypes = {
  data: PropTypes.shape({
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
    chapters: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          field_title_string: PropTypes.string,
          full_slug: PropTypes.string,
        })
      ),
    }),
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    reportHero: BLOK,
    body: BLOK_ARRAY,
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
    lang: PropTypes.string,
    heroArtwork: MEDIA,
    next: STORYBLOK_ENTRY,
    previous: STORYBLOK_ENTRY,
    UIStrings: PropTypes.shape({
      previousButtonTitle: PropTypes.string,
      previousButtonDescription: PropTypes.string,
      nextButtonTitle: PropTypes.string,
      nextButtonDescription: PropTypes.string,
      downloadFormTitle: PropTypes.string,
      downloadFormDescription: PropTypes.string,
      downloadButtonText: PropTypes.string,
    }),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  fragment allReportChapters on Query {
    chapters: allStoryblokEntry(
      filter: { id: { in: $allReportChapterIds } }
      sort: { fields: sort_by_date }
    ) {
      nodes {
        id
        full_slug
        field_title_string
        field_subtitle_string
      }
    }
  }
  fragment allSpecialisedReportAssets on Query {
    assets: allFile(filter: { id: { in: $allAssets } }) {
      nodes {
        id
        publicURL
        fields {
          aspectRatio
        }
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query APMReportQuery(
    $id: String
    $langRegex: String
    $allAssets: [String]
    $allReportChapterIds: [String]
  ) {
    ...story
    ...allSpecialisedReportAssets
    ...allReportChapters
    ...global
  }
`;
