import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "~components/molecules/button/button/button";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import {
  arrowNextStyle,
  arrowPreviousStyle,
} from "./carousel_control_arrow.module.scss";

function CarouselControlArrow({
  className: reactSlickArrowClassName,
  sliderRef,
  isNextButtonDisabled,
  totalNumberOfSlides,
  onClick: reactSlickOnClick,
  setIsNextButtonDisabled,
  slidesToShow,
  type,
}) {
  const handleClick = reactSlickOnClick || (() => {});
  const isDisabled = useMemo(() => {
    return reactSlickArrowClassName.includes("slick-disabled");
  }, [reactSlickArrowClassName]);

  useEffect(() => {
    if (type === "Next") {
      setIsNextButtonDisabled(isDisabled);
    }
  }, [isDisabled, setIsNextButtonDisabled, type]);

  const handleGoToSlide = (index) => {
    sliderRef.current?.slickGoTo(index);
  };

  const handleArrowClick = () => {
    if (type !== "Previous" || !isNextButtonDisabled) {
      handleClick();
    } else {
      handleGoToSlide(totalNumberOfSlides - slidesToShow - 1);
    }
  };

  if (type === "Previous") {
    return (
      <div className={classNames(arrowPreviousStyle, reactSlickArrowClassName)}>
        <Button
          color="charcoalTransparent"
          isDisabled={isDisabled}
          isFullWidth
          onClick={handleArrowClick}
          size="square"
        >
          <SVGIcon
            color="cloudBurst"
            height={16}
            icon="icon_chevron_left"
            width={16}
          />
        </Button>
      </div>
    );
  }

  if (type === "Next") {
    return (
      <div className={classNames(arrowNextStyle, reactSlickArrowClassName)}>
        <Button
          color="charcoalTransparent"
          isDisabled={isDisabled}
          isFullWidth
          onClick={handleArrowClick}
          size="square"
        >
          <SVGIcon
            color="cloudBurst"
            height={16}
            icon="icon_chevron_right"
            width={16}
          />
        </Button>
      </div>
    );
  }

  return null;
}

CarouselControlArrow.propTypes = {
  className: PropTypes.string,
  isNextButtonDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func.isRequired,
  sliderRef: PropTypes.shape({
    current: PropTypes.shape({
      slickGoTo: PropTypes.func,
    }),
  }).isRequired,
  slidesToShow: PropTypes.number.isRequired,
  totalNumberOfSlides: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["Previous", "Next"]).isRequired,
};

CarouselControlArrow.defaultProps = {
  className: "",
  onClick: () => {},
};

export default CarouselControlArrow;
