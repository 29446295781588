import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import CarouselControlArrow from "~components/organisms/carousel/components/carousel_control/carousel_control_arrow/carousel_control_arrow";
import { sliderStyle } from "./carousel_with_arrows_wrapper.module.scss";

function CarouselWithArrowsWrapper({
  children,
  sliderRef,
  additionalCarouselProps,
  totalNumberOfSlides,
  slidesToShow,
}) {
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const carouselProps = useMemo(() => {
    return {
      arrows: true,
      autoplay: false,
      centerMode: false,
      className: sliderStyle,
      dots: false,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow,
      speed: 400,
      prevArrow: (
        <CarouselControlArrow
          isNextButtonDisabled={isNextButtonDisabled}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          sliderRef={sliderRef}
          slidesToShow={slidesToShow}
          totalNumberOfSlides={totalNumberOfSlides}
          type="Previous"
        />
      ),
      nextArrow: (
        <CarouselControlArrow
          isNextButtonDisabled={isNextButtonDisabled}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          sliderRef={sliderRef}
          slidesToShow={slidesToShow}
          totalNumberOfSlides={totalNumberOfSlides}
          type="Next"
        />
      ),
    };
  }, [isNextButtonDisabled, totalNumberOfSlides, slidesToShow, sliderRef]);

  return (
    <Slider ref={sliderRef} {...carouselProps} {...additionalCarouselProps}>
      {children}
    </Slider>
  );
}

CarouselWithArrowsWrapper.propTypes = {
  additionalCarouselProps: PropTypes.shape({}),
  children: PropTypes.node,
  sliderRef: PropTypes.shape({
    current: PropTypes.shape({
      slickGoTo: PropTypes.func,
    }),
  }).isRequired,
  totalNumberOfSlides: PropTypes.number.isRequired,
  slidesToShow: PropTypes.number,
};

CarouselWithArrowsWrapper.defaultProps = {
  additionalCarouselProps: {},
  children: null,
  slidesToShow: null,
};

export default CarouselWithArrowsWrapper;
