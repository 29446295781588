import { useEffect, useRef } from "react";

export default function useSmoothScrollToNav({ shouldScrollToNav }) {
  const scrollRef = useRef();

  // scroll nav into view if loaded from link with `shouldScrollToNav` prop set
  useEffect(() => {
    if (scrollRef.current && shouldScrollToNav) {
      return scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    return null;
  }, [shouldScrollToNav]);

  return { scrollRef };
}
