import React from "react";
import PropTypes from "prop-types";
import SRChapterCard from "~components/molecules/specialised_report/sr_chapter_card/sr_chapter_card";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";

function SRChapterCardWithSmoothScrollLink({
  title,
  fullSlug,
  sliderCardCurrentIndex,
  isCardActive,
  index,
}) {
  const link = createInternalLink(fullSlug, {
    shouldScrollToNav: true,
    disableScrollUpdate: true,
    prevChapterIndex: sliderCardCurrentIndex,
  });

  return (
    <SRChapterCard
      chapterNumber={index}
      link={link}
      title={title}
      isCardActive={isCardActive}
    />
  );
}

SRChapterCardWithSmoothScrollLink.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  fullSlug: PropTypes.string,
  sliderCardCurrentIndex: PropTypes.number,
  isCardActive: PropTypes.bool,
};

SRChapterCardWithSmoothScrollLink.defaultProps = {
  title: null,
  index: 0,
  fullSlug: null,
  sliderCardCurrentIndex: 0,
  isCardActive: false,
};

export default SRChapterCardWithSmoothScrollLink;
