import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import CarouselWithArrowsWrapper from "~components/organisms/carousel/components/carousel_controls_wrapper/carousel_with_arrows_wrapper/carousel_with_arrows_wrapper";
import SRChapterCardWithSmoothScrollLink from "./components/sr_chapter_card_with_smooth_scroll_link";
import { wrapperStyle } from "./sr_chapter_card_nav.module.scss";

const H3_MARGIN = { marginBottom: "md" };

const SCREEN_SIZES = {
  XXL: 1178,
  XL: 1080,
  L: 920,
  M: 768,
  S: 576,
  XS: 360,
};

function SRChapterCardNav({
  allChaptersText,
  chapters,
  currentChapterIndex,
  prevChapterIndex,
}) {
  const [sliderCardCurrentIndex, setSliderCardCurrentIndex] = useState();
  const [slidesToShow, setSlidesToShow] = useState(3);
  const sliderRef = useRef();
  const numberOfChapters = chapters?.length;

  const handleGoToSlide = (index) => {
    sliderRef.current?.slickGoTo(index);
  };

  useEffect(() => {
    if (prevChapterIndex !== null) {
      return setTimeout(() => {
        return handleGoToSlide(currentChapterIndex);
      }, 400);
    }
    return null;
  }, [currentChapterIndex, prevChapterIndex]);

  const chapterCardCarouselProps = useMemo(() => {
    return {
      afterChange: (index) => {
        return setSliderCardCurrentIndex(index);
      },
      initialSlide:
        prevChapterIndex !== null ? prevChapterIndex : currentChapterIndex,
      slidesToShow,
      responsive: [
        {
          breakpoint: SCREEN_SIZES.XXL,
          settings: {
            slidesToShow,
            onReInit: () => {
              return setSlidesToShow(3);
            },
          },
        },
        {
          breakpoint: SCREEN_SIZES.L,
          settings: {
            slidesToShow,
            onReInit: () => {
              return setSlidesToShow(2);
            },
          },
        },
        {
          breakpoint: SCREEN_SIZES.S,
          settings: {
            slidesToShow,
            onReInit: () => {
              return setSlidesToShow(1);
            },
          },
        },
      ],
    };
  }, [currentChapterIndex, prevChapterIndex, slidesToShow]);

  return (
    <div className={wrapperStyle} id="chapter-card-nav">
      <Contain>
        <Heading visualLevel={5} seoLevel={3} margin={H3_MARGIN} isExtraBold>
          {allChaptersText}
        </Heading>
        <CarouselWithArrowsWrapper
          additionalCarouselProps={chapterCardCarouselProps}
          sliderRef={sliderRef}
          totalNumberOfSlides={numberOfChapters}
          slidesToShow={slidesToShow}
        >
          {chapters?.length > 0 &&
            chapters.map((chapter, index) => {
              if (chapter.field_title_string) {
                return (
                  <SRChapterCardWithSmoothScrollLink
                    key={chapter.field_title_string}
                    title={chapter.field_title_string}
                    fullSlug={chapter.full_slug}
                    sliderCardCurrentIndex={sliderCardCurrentIndex}
                    index={index}
                    isCardActive={currentChapterIndex === index}
                  />
                );
              }
              return null;
            })}
        </CarouselWithArrowsWrapper>
      </Contain>
    </div>
  );
}

SRChapterCardNav.propTypes = {
  allChaptersText: PropTypes.string,
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        field_title_string: PropTypes.string,
        full_slug: PropTypes.string,
      }),
    })
  ).isRequired,
  currentChapterIndex: PropTypes.number,
  prevChapterIndex: PropTypes.number,
};

SRChapterCardNav.defaultProps = {
  allChaptersText: "Chapters",
  currentChapterIndex: 0,
  prevChapterIndex: null,
};

export default SRChapterCardNav;
