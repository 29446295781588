import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";
import ModalForm from "~components/organisms/modal/modal_form/modal_form";
import { COLOR } from "../../../../../../prop_types";
import { buttonWrapperStyle, innerStyle } from "./sr_hero_inner.module.scss";

const MARGIN_BOTTOM_LG = { marginBottom: "xl" };
function SRHeroInner({
  buttonText,
  downloadFormDescription,
  downloadFormId,
  downloadFormTitle,
  lang,
  reportExtraInformation,
  reportSubtitle,
  reportTitle,
  reportType,
  textColor,
  fontSize,
}) {
  const [isDownloadModalActive, setIsDownloadModalActive] = useState(false);

  const toggleModal = useCallback(() => {
    return setIsDownloadModalActive((currentState) => {
      return !currentState;
    });
  }, []);

  return (
    <>
      <div className={innerStyle}>
        <Contain>
          {reportType && (
            <Heading
              isCentered
              isUppercase
              color={textColor}
              seoLevel={1}
              visualLevel={6}
              margin={{ marginBottom: fontSize === "normal" ? "lg" : "sm" }}
            >
              {reportType}
            </Heading>
          )}
          {reportTitle && (
            <Heading
              isCentered
              isXXL={fontSize === "normal"}
              isUppercase
              color={textColor}
              seoLevel={2}
              visualLevel={1}
              margin={{ marginBottom: fontSize === "normal" ? "lg" : "sm" }}
            >
              {reportTitle}
            </Heading>
          )}
          {reportSubtitle && (
            <Heading
              balanceText
              isCentered
              color={textColor}
              seoLevel={2}
              visualLevel={5}
              margin={MARGIN_BOTTOM_LG}
            >
              {reportSubtitle}
            </Heading>
          )}
          {reportExtraInformation && (
            <Heading
              balanceText
              isCentered
              color={textColor}
              seoLevel={2}
              visualLevel={6}
              isBaseFontWeight
              margin={MARGIN_BOTTOM_LG}
            >
              {reportExtraInformation}
            </Heading>
          )}
          <div className={buttonWrapperStyle}>
            <Button isCentered onClick={toggleModal}>
              {buttonText}
            </Button>
          </div>
        </Contain>
      </div>
      {/* ————— MODAL ————— */}
      <ModalForm
        formDescription={downloadFormDescription}
        formId={downloadFormId}
        formTitle={downloadFormTitle}
        isModalActive={isDownloadModalActive}
        lang={lang}
        setModalActiveState={setIsDownloadModalActive}
      />
    </>
  );
}

SRHeroInner.propTypes = {
  buttonText: PropTypes.string,
  downloadFormDescription: PropTypes.string,
  downloadFormId: PropTypes.string,
  downloadFormTitle: PropTypes.string,
  lang: PropTypes.string,
  reportExtraInformation: PropTypes.string,
  fontSize: PropTypes.oneOf(["normal", "small"]),

  reportSubtitle: PropTypes.string,
  reportTitle: PropTypes.string,
  reportType: PropTypes.string,
  textColor: COLOR,
};

SRHeroInner.defaultProps = {
  buttonText: "Download the PDF",
  downloadFormDescription: "We just need a few details from you first",
  downloadFormId: null,
  downloadFormTitle: "Download the report",
  lang: "en-gb",
  reportExtraInformation: null,
  fontSize: "normal",
  reportSubtitle: null,
  reportTitle: null,
  reportType: null,
  textColor: null,
};

export default SRHeroInner;
